import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import {
  DataTableContextType,
  useDataTableContext,
} from "app/contexts/DataTableContext";
import {
  ExportColumns,
  ExportRows,
  ExportType,
  exportValues,
} from "./export/exportValues";
import { CircularProgress } from "@material-ui/core";

type ExportKey =
  | "excel"
  | "excel-filter"
  | "csv"
  | "csv-filter"
  | "pdf"
  | "pdf-filter";

function getExportTypeFromExportKey(key: ExportKey): ExportType {
  switch (key) {
    case "excel":
    case "csv":
    case "pdf":
      return key;
    case "excel-filter":
      return "excel";
    case "csv-filter":
      return "csv";
    case "pdf-filter":
      return "pdf";
  }
}

const options: { key: ExportKey; label: string }[] = [
  {
    label: "Excel",
    key: "excel",
  },
  {
    label: "CSV",
    key: "csv",
  },
  {
    label: "PDF",
    key: "pdf",
  },
  {
    label: "Excel (righe filtrate)",
    key: "excel-filter",
  },
  {
    label: "CSV (righe filtrate)",
    key: "csv-filter",
  },
  {
    label: "PDF (righe filtrate)",
    key: "pdf-filter",
  },
];

export const DataTableExportButtons = (props: { filename: string }) => {
  const context: any = useDataTableContext();

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<any>(null);

  const [exporting, setExporting] = useState(false);

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    key: ExportKey
  ) => {
    setExporting(true);
    exportData(key, context, props.filename).finally(() => {
      setExporting(false);
    });
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: any) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <Button
        ref={anchorRef}
        onClick={handleToggle}
        variant="outlined"
        size="small"
        disabled={exporting}
        startIcon={exporting ? <CircularProgress size={20} /> : undefined}
      >
        Esporta
        <ArrowDropDownIcon style={{ fontSize: "20px" }} />
      </Button>
      <Popper
        style={{
          zIndex: 1000,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={index}
                      disabled={false}
                      onClick={(event) =>
                        handleMenuItemClick(event, option.key)
                      }
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

async function exportData(
  key: ExportKey,
  context: DataTableContextType,
  filename: string
): Promise<void> {
  const { columns, rowSourceOptions, sourceKey } = context;

  const source = context.exportSource ?? context.source;

  let rows: any[];
  const filteredRows = key.indexOf("-filter") > 0;
  if (context.server) {
    const params: any = {
      ...context,
      page: 0,
      pageSize: 1_000_000,
    };

    if (!filteredRows) {
      delete params.columnFilters;
      delete params.globalFilter;
    }

    rows = await context.fetchFromServer(params, true);
  } else {
    rows = filteredRows ? context.filteredRows : context.allRows;
  }

  const getRowSource = (row) => {
    const rowSource = source.getChildDataSource(
      sourceKey ? [sourceKey, { id: row.id }] : [{ id: row.id }],
      {
        itemId: row.id,
        initialData: {},
        ...rowSourceOptions,
      }
    );
    return rowSource;
  };

  const data: ExportRows = [];
  const exportColumns: ExportColumns = columns.filter((column) => {
    if (column.hidden || column.hiddenInExport) {
      return false;
    }
    return true;
  });

  for (let i = 0; i < rows.length; i++) {
    const row = rows[i];
    const rowData: any[] = [];
    const rowSource = getRowSource(row);

    exportColumns.forEach((column, colIndex) => {
      if (column.hidden || column.hiddenInExport) {
        return;
      }

      const value = rowSource.getValue(column.path);
      const cellContent = column.renderRawValue
        ? column.renderRawValue(value, row, i, rowSource)
        : column.renderCell
        ? column.renderCell(value, row, i, rowSource)
        : value;

      rowData.push(cellContent);
    });
    data.push(rowData);

    if (i % 100 === 0) {
      await new Promise<void>((resolve) => {
        setTimeout(() => {
          resolve();
        }, 1);
      });
    }
  }

  // console.log({ key, exportColumns, rows });
  // console.log("result", data);

  await exportValues(
    data,
    exportColumns,
    {
      filename: filename,
    },
    getExportTypeFromExportKey(key)
  );
}
