import { useApi } from "../useApi";
import { useEffect, useRef } from "react";
import { dropRight, get, isEqual, isNil, union } from "lodash";
import fpSet from "lodash/fp/set";
import {
  isTempId,
  newTempId,
  nop,
  nullablePathGet,
  nullablePathSet,
  omitArray,
  recursivelyOmitTempId,
  usePatchableState,
} from "./utils";
import { useValidation } from "./useValidation";
import { useUncommittedChangesConfirmationContext } from "../../contexts/UncommittedChangesConfirmationContext";

type PathElement = string | any;

type TempId = string;

export type DataSourceCreateOptions = {
  parent?: DataSourceInterface;
  pathInParent?: PathElement[];
  initialData?;
  itemId?: number | string;

  loadAction?;
  autoLoad?: boolean;
  onLoadSuccess?;
  onLoadError?;

  createAction?;
  onCreateSuccess?;
  onCreateError?;

  editAction?;
  onEditSuccess?;
  onEditError?;

  deleteAction?;
  onDeleteSuccess?;
  onDeleteError?;
  skipCheckUncommittedChanged?: boolean;
};

export type DataPath = number | string | PathElement | PathElement[];

export interface DataSourceInterface {
  loadSuccess: boolean;
  data;

  loading: boolean;

  loadError: any;

  load: (...loadArgs: any[]) => Promise<any>;

  getValue(idOrKeyOrPath: DataPath, defaultValue?);

  changeValue(idOrKeyOrPath: DataPath, newValue): void;

  _changeContent(content: any): void;

  create(initialValue?): TempId;

  delete(idOrKeyOrPath?: DataPath): void;

  clear(): void;

  getChildDataSource(path: PathElement[], options?: any): DataSourceInterface;

  commit(): Promise<void>;

  cancel(idOrKeyOrPath?: DataPath): void;

  runValidation(path: DataPath): void;

  getValidationErrors(path: DataPath, isParialPath?: boolean): string[];
}

export class DataSource implements DataSourceInterface {
  // eslint-disable-next-line no-useless-constructor
  constructor(private readonly options: DataSourceCreateOptions) {}

  getValue(idOrKeyOrPath: DataPath, defaultValue = "") {
    const path = createPath(idOrKeyOrPath, this.options.pathInParent);
    return this.options.parent!.getValue(path, defaultValue);
  }

  changeValue(idOrKeyOrPath: DataPath, newValue) {
    const path = createPath(idOrKeyOrPath, this.options.pathInParent);
    return this.options.parent!.changeValue(path, newValue);
  }

  _changeContent(content: any) {
    this.options.parent!.changeValue(this.options.pathInParent, content);
  }

  create(initialValue: any = {}): TempId {
    const id = initialValue.id || newTempId();
    const newItem = {
      ...initialValue,
      id,
      tempId: id,
      __isNew: true,
    };
    this.options.parent!.changeValue(this.options.pathInParent, [
      ...this.data,
      newItem,
    ]);
    return id;
  }

  delete(idOrKeyOrPath: DataPath) {
    const path = idOrKeyOrPath
      ? createPath(idOrKeyOrPath, this.options.pathInParent)
      : this.options.pathInParent;
    this.options.parent!.delete(path);
  }

  getChildDataSource(path: PathElement[], options = {}): DataSourceInterface {
    return new DataSource({
      parent: this,
      pathInParent: path,
      ...options,
    } as any) as any;
  }

  // @ts-ignore:
  commit(path: PathElement[]) {
    return (this.options.parent!.commit as any)(this.options.pathInParent);
  }

  runValidation(idOrKeyOrPath: DataPath) {
    const path = createPath(idOrKeyOrPath, this.options.pathInParent);
    this.options.parent!.runValidation(path);
  }

  getValidationErrors(idOrKeyOrPath: DataPath, isParialPath: boolean = false) {
    const path = createPath(idOrKeyOrPath, this.options.pathInParent);
    return this.options.parent!.getValidationErrors(path, isParialPath);
  }

  get data() {
    return (
      this.options.parent!.getValue(this.options.pathInParent) ||
      this.options.initialData
    );
  }
}

export function useDataSource({
  parent,
  pathInParent = [],
  initialData,
  itemId,

  loadAction,
  autoLoad = true,
  onLoadSuccess = nop,
  onLoadError = nop,

  createAction,
  onCreateSuccess = nop,
  onCreateError = nop,

  editAction,
  onEditSuccess = nop,
  onEditError = nop,

  deleteAction,
  onDeleteSuccess = nop,
  onDeleteError = nop,
  skipCheckUncommittedChanged = false,
}: DataSourceCreateOptions = {}): DataSourceInterface {
  if (typeof initialData === "undefined" || initialData === null) {
    throw new Error("useDataSource: initialData is mandatory");
  }

  const [state, setState] = usePatchableState({
    data: initialData,
    initialData: initialData,
    newItemsId: [],
    editedItemsId: [],

    loading: autoLoad && loadAction,
    loadSuccess: false,
    loadResponse: null,
    loadError: null,

    creating: false,
    createSuccess: false,
    createResponse: null,
    createError: null,

    editing: false,
    editSuccess: false,
    editResponse: null,
    editError: null,
  });
  const data = parent ? parent.getValue(pathInParent, initialData) : state.data;

  const loadApi = useApi(loadAction);
  const createApi = useApi(createAction);
  const editApi = useApi(editAction);
  const deleteApi = useApi(deleteAction);

  async function load(...loadArgs) {
    if (itemId) {
      loadArgs = [itemId, ...loadArgs];
    }
    setState({ loading: true });
    try {
      const response = await loadApi.callApi(...loadArgs);
      if (!response) {
        return;
      }
      setState({
        data: response.data,
        initialData: response.data,
        loading: false,
        loadSuccess: true,
        loadResponse: response,
        loadError: null,
      });
      onLoadSuccess(response);
      return response.data;
    } catch (e) {
      setState({
        initialData: null,
        loading: false,
        loadSuccess: false,
        loadResponse: null,
        loadError: e,
      });
      onLoadError(e);
    }
  }

  const validator = (
    ((Array.isArray(data) && data.length) ||
    (data?.id && typeof data.id === "number")
      ? editAction
      : createAction) ||
    createAction ||
    editAction
  )?.validator;
  const validation = useValidation(validator, data);

  // fetch initial data
  useEffect(() => {
    if (loadAction && autoLoad) {
      load();
    }
  }, [autoLoad, itemId]);

  function getValue(idOrKeyOrPath: DataPath, defaultValue = "") {
    const path = createPath(idOrKeyOrPath, pathInParent);
    if (parent) {
      return parent.getValue(path, defaultValue);
    } else {
      const jsonPath = convertPathToJsonPath(state.data, path);
      return get(state.data, jsonPath, defaultValue);
    }
  }

  const { setShouldAskConfirmation } =
    useUncommittedChangesConfirmationContext();
  const rootDataSourceModified =
    !parent &&
    (state.initialData
      ? // se c'è initialData, dovrebbe essere rimasto uguale
        !isEqual(state.initialData, state.data)
      : // se non c'è initialData (null o undefined)
        // data potrebbe essere:
        // - null/undefined
        // - oggetto vuoto, ancora non c'è niente da salvare
        !(!state.data || isEqual(state.initialData || {}, state.data)));

  useEffect(() => {
    setShouldAskConfirmation(
      !skipCheckUncommittedChanged && rootDataSourceModified
    );
    return () => setShouldAskConfirmation(false);
  }, [skipCheckUncommittedChanged, rootDataSourceModified]);

  function create(initialValue: any = {}, { first }: { first?: boolean } = {}) {
    // TODO: qui ha senso aggiungere la path come parametro?
    const id = initialValue.id || newTempId();
    const newItem = {
      ...initialValue,
      id,
      tempId: id,
      __isNew: true, // TODO: ha senso questa flag? semplifica nella ui. Meglio mettere __ anche a tempId?
    };

    if (parent) {
      if (first) {
        changeValue([], [newItem, ...data]);
      } else {
        changeValue([], [...data, newItem]);
      }
    } else {
      if (first) {
        setState({
          data: [newItem, ...state.data],
          newItemsId: [newItem.id, ...state.newItemsId],
        });
      } else {
        setState({
          data: [...state.data, newItem],
          newItemsId: [...state.newItemsId, newItem.id],
        });
      }
    }
    return id;
  }

  function changeValue(idOrKeyOrPath: DataPath, newValue) {
    const path = createPath(idOrKeyOrPath, pathInParent);
    if (parent) {
      parent.changeValue(path, newValue);
    } else {
      setState((state) => ({
        data: fpSet(
          convertPathToJsonPath(state.data, path),
          newValue,
          state.data
        ),
        editedItemsId: union(state.editedItemsId, [path[0].id]),
      }));
    }
  }

  function _changeContent(content: any) {
    if (parent) {
      parent.changeValue(pathInParent, content);
    } else {
      setState({ data: content });
    }
  }

  async function _delete(idOrKeyOrPath: DataPath) {
    if (idOrKeyOrPath) {
      // delete something inside the data
      const path = createPath(idOrKeyOrPath, pathInParent);
      if (parent) {
        parent.delete(path);
      } else {
        setState((state) => ({
          data: omitArray(state.data, convertPathToJsonPath(state.data, path)),
        }));
      }
    } else if (deleteAction) {
      // delete this using the API
      setState({
        deleting: true,
      });
      try {
        const response = await deleteApi.callApi(itemId);
        setState({
          deleting: false,
          deleteSuccess: true,
          deleteResponse: response,
          deleteError: false,
        });
        if (parent) {
          // @ts-ignore:
          parent.onItemDeletedInServer(pathInParent);
        }
        onDeleteSuccess();
      } catch (e) {
        setState({
          deleting: false,
          deleteError: e,
        });
        onDeleteError(e);
      }
    } else if (parent) {
      // just delete this in the parent
      parent.delete(pathInParent);
    }
  }

  async function commit_() {
    if (createAction) {
      if (Array.isArray(data)) {
        // create array of items
        console.log("commit create collection not implemented");
      } else if (isTempId(data.id)) {
        // create single item
        setState({
          creating: true,
        });
        try {
          const _d = recursivelyOmitTempId(data);
          const response = await createApi.callApi(_d); // omit(data, ['id'])
          if (parent) {
            setState({
              creating: false,
              createSuccess: true,
              createResponse: response,
              createError: null,
            });
            // @ts-ignore:
            parent.onNewItemCreatedInServer(data.id, {
              ...response.data,
              tempId: data.id,
            });
            validation.setValidationErrors([]);
          } else {
            setState({
              creating: false,
              createSuccess: true,
              createResponse: response,
              createError: null,
              data: response.data,
              initialData: response.data,
            });
            validation.setValidationErrors([]);
          }
          onCreateSuccess(response);
        } catch (e: any) {
          setState({
            creating: false,
            createSuccess: false,
            createResponse: null,
            createError: e,
          });
          if (e.validationErrors) {
            validation.setValidationErrors(e.validationErrors);
          }
          onCreateError(e);
        }
      }
    }

    if (editAction) {
      if (Array.isArray(data)) {
        const editedItems = state.editedItemsId.map((id) =>
          data.find((item) => item.id === id)
        );
        setState({
          editing: true,
        });
        try {
          const response = await editApi.callApi(editedItems);
          if (parent) {
            // TODO: creazione di piu' oggetti in contemporanea non ancora implementata
          } else {
            const data = state.data.map((item) =>
              // @ts-ignore:
              state.editedItemsId.includes(item.id)
                ? response.data.find((eItem) => eItem.id === item.id)
                : item
            );
            setState({
              data,
              initialData: data,
              editedItemsId: [],
              editing: false,
              editSuccess: true,
              editResponse: response,
              editError: null,
            });
          }
        } catch (e) {
          setState({
            editing: false,
            editSuccess: false,
            editResponse: null,
            editError: e,
          });
        }
      } else if (data.id && typeof data.id === "number") {
        try {
          setState({
            editing: true,
          });
          const response = await editApi.callApi(
            data.id,
            recursivelyOmitTempId(data)
          );
          if (parent) {
            setState({
              editing: false,
              editSuccess: true,
              editError: null,
              editResponse: response,
            });
            // @ts-ignore:
            parent.onItemEditedInServer(response.data);
            validation.setValidationErrors([]);
          } else {
            setState({
              data: response.data,
              initialData: response.data,
              editing: false,
              editSuccess: true,
              editError: null,
              editResponse: response,
            });
            validation.setValidationErrors([]);
          }
          onEditSuccess(response);
        } catch (e: any) {
          setState({
            editing: false,
            editSuccess: false,
            editError: e,
            editResponse: e,
          });
          if (e.validationErrors) {
            validation.setValidationErrors(e.validationErrors);
          }
          onEditError(e);
        }
      }
    }
  }
  const commitRef = useRef(commit_);
  commitRef.current = commit_;

  const commit = useRef(async () => {
    await commitRef.current();
  }).current;

  function clear() {
    // TODO: check if this is an array
    if (parent) {
      parent.changeValue(pathInParent, state.initialData || initialData);
    } else {
      setState({
        data: state.initialData || initialData,
      });
      validation.setValidationErrors([]);
    }
  }

  function cancel(idOrKeyOrPath: DataPath = []) {
    const path = idOrKeyOrPath
      ? createPath(idOrKeyOrPath, pathInParent)
      : pathInParent;
    if (parent) {
      parent.cancel(path);
    } else {
      setState((state) => {
        const jsonPath = convertPathToJsonPath(state.data, path);

        let previousValue = nullablePathGet(state.initialData, jsonPath);
        if (isNil(previousValue)) {
          const parentPath = dropRight(jsonPath);
          if (Array.isArray(nullablePathGet(state.initialData, parentPath))) {
            const id = getValue(path).id;
            previousValue = {
              id,
              tempId: id,
              __isNew: true,
            };
          }
        }

        return {
          data: nullablePathSet(jsonPath, previousValue, state.data),
        };
      });
    }
  }

  function onNewItemCreatedInServer(tempId, newData) {
    const data = fpSet(
      convertPathToJsonPath(state.data, [{ id: tempId }]),
      newData,
      state.data
    );
    setState({
      data,
      initialData: data,
      newItemsId: state.newItemsId.filter((id) => id !== tempId),
    });
  }

  function onItemEditedInServer(editedData) {
    const data = fpSet(
      convertPathToJsonPath(state.data, [{ id: editedData.id }]),
      editedData,
      state.data
    );
    setState({
      data,
      initialData: data,
    });
  }

  function onItemDeletedInServer(path) {
    const data = omitArray(state.data, convertPathToJsonPath(state.data, path));
    setState(() => ({
      data,
      initialData: data,
    }));
  }

  function runValidation(idOrKeyOrPath: DataPath = []) {
    if (validator) {
      const jsonPath = convertPathToJsonPath(data, createPath(idOrKeyOrPath));
      if (jsonPath) {
        const yupPath = convertDataSourcePathToYupPath(jsonPath);
        validation.runValidation(yupPath);
      }
    }
    if (parent) {
      parent.runValidation(createPath(idOrKeyOrPath, pathInParent));
    }
  }

  function getValidationErrors(
    idOrKeyOrPath: DataPath = [],
    isParialPath: boolean = false
  ) {
    let errors = [];

    if (validator) {
      const jsonPath = convertPathToJsonPath(data, createPath(idOrKeyOrPath));
      if (jsonPath) {
        const yupPath = convertDataSourcePathToYupPath(jsonPath);
        // @ts-ignore:
        errors = validation.getValidationErrors(yupPath, isParialPath);
      }
    }

    if (parent) {
      // @ts-ignore:
      errors = [
        ...errors,
        ...parent.getValidationErrors(
          createPath(idOrKeyOrPath, pathInParent),
          isParialPath
        ),
      ];
    }

    return errors;
  }

  const self: DataSourceInterface = {
    ...state,
    loading: state.loading || (parent ? parent.loading : false),
    data,

    _loadAction: loadAction,
    load,
    getValue,
    changeValue,
    _changeContent,
    create,
    delete: _delete,
    clear,
    commit,
    cancel,
    onNewItemCreatedInServer,
    onItemEditedInServer,
    onItemDeletedInServer,
    runValidation,
    getValidationErrors,
  } as any;

  const selfRef = useRef(self);
  selfRef.current = self;

  // @ts-ignore:
  self.getChildDataSource = function (path: PathElement[], options = {}) {
    return new DataSource({
      parent: selfRef.current,
      pathInParent: path,
      ...options,
    });
  };

  return self;
}

function createPath(
  idOrKeyOrPath: DataPath,
  pathInParent: PathElement[] = []
): PathElement[] {
  if (typeof idOrKeyOrPath === "number") {
    return [...pathInParent, { id: idOrKeyOrPath }];
  } else if (typeof idOrKeyOrPath === "string") {
    return [...pathInParent, idOrKeyOrPath];
  } else if (Array.isArray(idOrKeyOrPath)) {
    return [...pathInParent, ...idOrKeyOrPath];
  } else {
    return [...pathInParent, idOrKeyOrPath];
  }
}

function convertPathToJsonPath(data, path: PathElement[]) {
  const jsonPath = [];
  let currentValue = data;
  for (const p of path) {
    if (!currentValue) {
      return currentValue;
    }

    if (typeof p === "string" || typeof p === "number") {
      currentValue = currentValue[p];
      // @ts-ignore:
      jsonPath.push(p);
    } else {
      const constraints = Object.entries(p);
      const index = currentValue.findIndex((item) => {
        for (const [constraintKey, constraintValue] of constraints) {
          if (item[constraintKey] !== constraintValue) {
            return false;
          }
        }

        // all constraints verified
        return true;
      });
      currentValue = currentValue[index];
      // @ts-ignore:
      jsonPath.push(index);
    }
  }
  return jsonPath;
}

function convertDataSourcePathToYupPath(jsonPath: (string | number)[]): string {
  let stringPath = "";
  for (const piece of jsonPath) {
    if (typeof piece === "number") {
      stringPath += `[${piece}]`;
    } else if (stringPath.length === 0) {
      stringPath += piece;
    } else {
      stringPath += `.${piece}`;
    }
  }
  return stringPath;
}

/**
 * Serializes a path so that it can be used as an id
 * @param idOrKeyOrPath
 */
export function serializePath(idOrKeyOrPath: DataPath) {
  return JSON.stringify(idOrKeyOrPath);
}
