/* eslint-disable no-case-declarations */
/* eslint-disable no-unneeded-ternary */
import numeral from "numeral";
import moment from "moment";
// import XLSX from 'xlsx';
import type * as ExcelType from "exceljs";
import * as Excel from "exceljs/dist/es5/exceljs.browser.js";
import StreamBuf from "exceljs/dist/es5/utils/stream-buf";
import { saveAs } from "file-saver";
// import { copy } from "common/copy";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { TableColumn, TableColumnArray } from "../types";

export type ExportType = "excel" | "csv" | "pdf";
export type ExportRow = any[];
export type ExportRows = ExportRow[];
export type ExportColumn = TableColumn;
export type ExportColumns = ExportColumn[];

type ExportOptions = {
  filename: string;
};

const methodOptions = {
  copy: {
    fieldBoundary: null,
    escapeChar: null,
    fieldSeparator: "\t",
    footer: null,
    header: true,
  },
  csv: {
    footer: null,
    header: true,
  },
  excel: {
    footer: null,
    header: true,
  },
};

export const datetimeFormats = {
  date: "YYYY-MM-DD",
  datetime: "YYYY-MM-DD HH:mm:ssZ",
  time: "HH:mm:ss",
};

export function datetimeParse(date, type) {
  if (!date) {
    return null;
  }
  const format = datetimeFormats[type];
  const m = moment(date, format);
  if (!m.isValid()) {
    return null;
  }
  return m.toDate();
}

const zeroString = "00000000000000000000000000000000000000000000000000";
export const defaultMaxDecimalDigits = 10;

export function getNumberFormat(
  decimalDigits: number | undefined,
  thousandSeparator: boolean
) {
  let optionalDecimals = false;
  if (typeof decimalDigits !== "number") {
    decimalDigits = defaultMaxDecimalDigits;
    optionalDecimals = true;
  }
  decimalDigits = Math.min(decimalDigits, 50);

  let decimalPart;
  if (decimalDigits === 0) {
    decimalPart = "";
  } else {
    decimalPart = zeroString.substring(0, decimalDigits);
    if (optionalDecimals) {
      decimalPart = ".[" + decimalPart + "]";
    } else {
      decimalPart = "." + decimalPart;
    }
  }
  let integerPart = "0";
  if (thousandSeparator) {
    integerPart = "0,0";
  }

  return integerPart + decimalPart;
}

function numberValue(value, decimalDigits = undefined) {
  if (typeof value === "string") {
    value = parseFloat(value);
  }
  if (typeof value !== "number" || isNaN(value)) {
    return null;
  }
  return value;
}

function numberValueText(
  value: any,
  decimalDigits: number | undefined,
  thousandSeparator: boolean
) {
  if (typeof value === "string") {
    value = parseFloat(value);
  }
  if (typeof value !== "number" || isNaN(value)) {
    return "";
  }

  const format = getNumberFormat(decimalDigits, thousandSeparator);
  return numeral(value).format(format);
}

const excelDateFormat = {
  date: "dd/mm/yyyy",
  datetime: "dd/mm/yyyy hh:mm:ss",
  time: "hh:mm:ss",
};

function formatDateTimeExcel(
  value: any,
  type: "date" | "time" | "datetime",
  method
) {
  if (!value) {
    return {
      value: null,
    };
  }

  if (method === "csv") {
    return {
      value: formatDateTimeText(value, type),
    };
  }

  if (type === "time") {
    const splitted = value.split(":");
    value = parseInt(splitted[2], 10) / 60;
    value = (value + parseInt(splitted[1], 10)) / 60;
    value = (value + parseInt(splitted[0], 10)) / 24;

    return {
      value: value,
    };
  } else if (type === "date") {
    const date = datetimeParse(value, type);
    if (!date) {
      return {
        value: null,
      };
    }
    // days between Jan 1, 1900 and Jan 1, 1970, plus 2 (Google "excel leap year bug")
    const msSince1900 = date.getTime() - date.getTimezoneOffset() * 60 * 1000;
    const excelDate = msSince1900 / (86400 * 1000) + (25567 + 2);
    return {
      value: excelDate,
    };
  } else {
    const date = datetimeParse(value, type);
    if (!date) {
      return {
        value: null,
      };
    }
    const timezoneFix =
      date && new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
    return {
      value: timezoneFix ? timezoneFix : null,
    };
  }
}

function formatDateTimeText(value, type) {
  const date = datetimeParse(value, type);
  if (!date) {
    return "";
  }
  const format =
    type === "DATE"
      ? "DD/MM/YYYY"
      : type === "TIME"
      ? "HH:mm:ss"
      : "DD/MM/YYYY HH:mm:ss";
  return moment(date).format(format);
}

function pixelsToPoints(pixels) {
  let value100;
  if (pixels < 12) {
    value100 = Math.round((pixels * 100) / 12);
  } else {
    if (pixels > 1790) {
      pixels = 1790;
    }
    value100 = Math.round((1 + (pixels - 12) / 7) * 100);
  }
  return value100 / 100;
}

// function newLine(options) {
//   return options.newline
//     ? options.newline
//     : navigator.userAgent.match(/Windows/)
//     ? "\r\n"
//     : "\n";
// }

function exportValueText(
  value: any,
  column: ExportColumn,
  options: ExportOptions,
  rowData: ExportRow
) {
  const type = column.type;
  if (
    !type ||
    typeof value === "undefined" ||
    (type !== "boolean" && value === null)
  ) {
    return "";
  }

  let text;
  switch (type) {
    case "text":
    case "enum":
      text = value || "";
      break;

    case "number":
      let decimalDigits: number | undefined;
      if (column.exportNumberFormat?.decimals) {
        decimalDigits = column.exportNumberFormat?.decimals;
      }
      text = numberValueText(
        value,
        decimalDigits,
        column.exportNumberFormat?.thousandSeparator ?? false
      );
      break;

    case "boolean":
      text = value ? "Sì" : "No"; // ☑" : "☐";
      break;

    case "date":
    case "datetime":
    case "time":
      text = formatDateTimeText(value, type);
      break;

    default:
      console.error("Export: Unknown type " + type);
      text = "";
  }

  return text;
}

// function createExcelEnumList(options) {
//   return (options.enumList || [])
//     .map((option) => {
//       return option.replace(/"/g, '""');
//     })
//     .join(",");
// }

function exportExcelCell(
  value: any,
  column: ExportColumn,
  options: ExportOptions,
  method: "excel" | "csv",
  rowData: ExportRow
) {
  const type = column.type;
  if (
    !type ||
    typeof value === "undefined" ||
    (type !== "boolean" && value === null)
  ) {
    return "";
  }

  switch (type) {
    case "text":
    case "enum":
      return {
        value: value || "",
      };

    // case "enum":
    //   return {
    //     value: value || "",
    //     dataValidation: {
    //       type: "list",
    //       allowBlank: true,
    //       formulae: ['"' + createExcelEnumList(column.options) + '"'],
    //     },
    //   };

    case "number":
      // case "DOUBLE":
      // case "DECIMAL":
      return {
        value: numberValue(value),
      };

    case "boolean":
      return {
        value: value ? true : false,
      };

    case "date":
    case "time":
    case "datetime":
      return formatDateTimeExcel(value, type, method);

    default:
      console.error("Export: Unknown type " + type);
      return {
        value: "",
      };
  }
}

async function exportExcel(
  rows: ExportRows,
  columns: ExportColumns,
  options: ExportOptions,
  method: "excel" | "csv"
) {
  options = { ...methodOptions[method], ...options };

  const numberOfHeaderRows = 1; // options.numberOfHeaderRows || 1;

  const workbook: ExcelType.Workbook = new Excel.Workbook();

  workbook.creator = "Creaconsulting";
  workbook.lastModifiedBy = "Creaconsulting";
  workbook.created = new Date();
  workbook.modified = new Date();
  (workbook as any).lastPrinted = null;

  const sheet = workbook.addWorksheet("Sheet1", {
    // header row/rows fixed
    views: [
      {
        state: "frozen",
        ySplit: numberOfHeaderRows,
      },
    ],
  });
  // auto filter
  sheet.autoFilter = {
    from: {
      row: numberOfHeaderRows,
      column: 1,
    },
    to: {
      row: numberOfHeaderRows,
      column: columns.length,
    },
  };

  sheet.columns = columns.map((column, index) => {
    const c: Partial<ExcelType.Column> = {
      header: column.header || "", // if name is an array, two rows are used!
      key: index as any,
      style: {},
    };
    if (column.width) {
      c.width = pixelsToPoints(column.width);
    }
    const type = column.type;
    if (type) {
      switch (type) {
        case "number":
          // let decimalDigits;
          // if (type === "INTEGER") {
          //   decimalDigits = 0;
          // } else if (type === "DECIMAL") {
          //   decimalDigits =
          //     (column.options && column.options.decimalDigits) || 0;
          // }
          // c.style.numFmt =
          //   "#,##0" +
          //   (decimalDigits ? "." + zeroString.substring(0, decimalDigits) : "");
          // break;
          // case "DOUBLE":
          c.style = c.style || {};
          c.style.numFmt = "General";
          if (column.exportNumberFormat?.thousandSeparator) {
            c.style.numFmt = "General;#,##0.####################";
            if (typeof column.exportNumberFormat.decimals === "number") {
              if (column.exportNumberFormat.decimals > 0) {
                c.style.numFmt =
                  "#,##0." +
                  zeroString.substring(0, column.exportNumberFormat.decimals);
              } else {
                c.style.numFmt = "#,##0";
              }
            }
          }
          break;
        case "date":
        case "time":
        case "datetime":
          c.style = c.style || {};
          c.style.numFmt = excelDateFormat[type];
          break;
      }
    }
    return c;
  });

  // header style
  for (
    let headerRowIndex = 0;
    headerRowIndex < numberOfHeaderRows;
    headerRowIndex++
  ) {
    const headerRow = sheet.getRow(headerRowIndex + 1);
    headerRow.font = { bold: true };
    headerRow.commit();
  }

  const sheetRows: any[] = [];

  if (rows) {
    for (let i = 0; i < rows.length; i++) {
      const row = rows[i];
      const sheetRow = columns.map((column, index) => {
        const cell = exportExcelCell(row[index], column, options, method, row);
        return cell;
      });
      sheetRows.push(sheetRow);

      if (i % 100 === 0) {
        await new Promise<void>((resolve) => {
          setTimeout(() => {
            resolve();
          }, 1);
        });
      }
    }
  }

  for (let i = 0; i < sheetRows.length; i++) {
    const sheetRow = sheetRows[i];
    const row = sheet.addRow(sheetRow.map((cell) => cell.value));
    sheetRow.forEach((c, index) => {
      const cell = row.getCell(index + 1);
      if (c.dataValidation) {
        cell.dataValidation = c.dataValidation;
      }
      if (c.type) {
        (cell as any).type = c.type;
      }
    });
    row.commit();

    if (i % 100 === 0) {
      await new Promise<void>((resolve) => {
        setTimeout(() => {
          resolve();
        }, 1);
      });
    }
  }

  if (method === "excel") {
    const filename = options.filename + ".xlsx";

    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: "application/octet-stream" });
      saveAs(blob, filename);
    });
  } else if (method === "csv") {
    const filename = options.filename + ".csv";

    const stream = new StreamBuf();
    workbook.csv
      .write(stream)
      .then(() => {
        return stream.read();
      })
      .then((data) => {
        const blob = new Blob([data], { type: "application/octet-stream" });
        saveAs(blob, filename);
      });
  }
}

// function copyValues(rows, columns, options, method) {
//   options = { ...methodOptions[method], ...options };

//   const boundary = options.fieldBoundary;
//   const separator = options.fieldSeparator;
//   const escapeChar = options.escapeChar;
//   const reBoundary = boundary && new RegExp(boundary, "g");
//   const nl = newLine(options);

//   let header = "";
//   if (options.header) {
//     const numberOfHeaderRows = options.numberOfHeaderRows || 1;
//     for (let headerIndex = 0; headerIndex < numberOfHeaderRows; headerIndex++) {
//       columns.forEach((column, index) => {
//         if (index > 0) {
//           header += separator;
//         }
//         const name = Array.isArray(column.name)
//           ? column.name[headerIndex]
//           : column.name;
//         const value = name || "";
//         header += boundary
//           ? boundary +
//             value.replace(reBoundary, escapeChar + boundary) +
//             boundary
//           : value;
//       });
//       header += nl;
//     }
//   }

//   const footer = "";

//   if (rows && !Array.isArray(rows)) {
//     rows = [rows];
//   }

//   const body = rows
//     ? rows.map((row) => {
//         let s = "";

//         columns.forEach((column, index) => {
//           if (index > 0) {
//             s += separator;
//           }
//           const value = exportValueText(row[column.key], column, options, row);
//           s += boundary
//             ? boundary +
//               value.replace(reBoundary, escapeChar + boundary) +
//               boundary
//             : value;
//         });

//         return s;
//       })
//     : "";

//   const toCopy = header + body.join(nl) + footer;

//   copy(toCopy, options.message);
// }

function applyPdfTableCellStyles(table, styles) {
  const doc = table.doc;
  const styleModifiers = {
    fillColor: doc.setFillColor,
    textColor: doc.setTextColor,
    fontStyle: doc.setFontStyle,
    lineColor: doc.setDrawColor,
    lineWidth: doc.setLineWidth,
    font: doc.setFont,
    fontSize: doc.setFontSize,
  };
  Object.keys(styleModifiers).forEach(function (name) {
    const style = styles[name];
    const modifier = styleModifiers[name];
    if (typeof style !== "undefined") {
      if (Array.isArray(style)) {
        modifier.apply(doc, style);
      } else {
        modifier(style);
      }
    }
  });
}

async function exportPdf(
  rows: ExportRows,
  columns: ExportColumns,
  options: ExportOptions,
  method: "pdf"
) {
  const numberOfHeaderRows = 1; // options.numberOfHeaderRows || 1;

  const tableRows: any[] = [];
  const tableColumns = columns.map((column) => {
    return (
      (column.header &&
        (Array.isArray(column.header) ? column.header[0] : column.header)) ||
      ""
    );
  });

  // if (numberOfHeaderRows > 1) {
  //   for (let index = 1; index < numberOfHeaderRows; index++) {
  //     const cols = columns.map((column) => {
  //       return (
  //         (column.name &&
  //           (Array.isArray(column.name) ? column.name[index] : column.name)) ||
  //         ""
  //       );
  //     });
  //     tableRows.push(cols);
  //   }
  // }

  if (rows && !Array.isArray(rows)) {
    rows = [rows];
  }
  if (rows) {
    rows.forEach((row) => {
      const tableRow = columns.map((column, index) => {
        const value = exportValueText(row[index], column, options, row);
        return value;
      });
      tableRows.push(tableRow);
    });
  }

  const filename = options.filename + ".pdf";

  // Only pt supported (not mm or in)
  // eslint-disable-next-line new-cap
  const doc = new jsPDF("p", "pt");

  await import("./arial-normal");
  doc.setFont("arial", "normal");

  (doc as any).autoTable(tableColumns, tableRows, {
    styles: {
      overflow: "linebreak",
      fontSize: 6,
      font: "arial",
    },
    drawCell: function (cell, data) {
      if (data.row.index < numberOfHeaderRows - 1) {
        const headerCell = data.table.headerRow.cells[data.column.index];
        applyPdfTableCellStyles(data.table, headerCell.styles);
      }
    },
  });

  if (method === "pdf") {
    doc.save(filename);
  } else if (method === "print") {
    doc.autoPrint();
    const blobUrl = doc.output("bloburl");

    const oldIframe = document.getElementById("print-helper-iframe");
    if (oldIframe) {
      const oldBlobUrl = oldIframe.dataset.blobUrl;
      if (oldBlobUrl) {
        global.URL.revokeObjectURL(oldBlobUrl);
      }
      oldIframe.remove();
    }

    const iframe = document.createElement("iframe");
    iframe.id = "print-helper-iframe";
    iframe.style.visibility = "hidden";
    iframe.src = blobUrl?.href || doc.output("dataurlstring");
    if (blobUrl) {
      iframe.dataset.blobUrl = blobUrl.href;
    }
    document.body.appendChild(iframe);
  }
}

export async function exportValues(
  rows: ExportRows,
  columns: ExportColumns,
  options: ExportOptions,
  method: ExportType
) {
  switch (method) {
    // case "copy":
    //   copyValues(rows, columns, options, method);
    //   break;

    case "excel":
    case "csv":
      await exportExcel(rows, columns, options, method);
      break;

    case "pdf":
      // case "print":
      exportPdf(rows, columns, options, method);
      break;
  }
}
